import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Sora', sans-serif;
  }

  ol, ul {
    list-style: none;
  }
  
  a {
    text-decoration: none;
  }

  html {
    max-width: 1600px;
    height: 100%;
    margin: 0 auto;
    background: #eee;
  }

  body {
    background: #FAF9FD;
    text-rendering: optimizeLegibility;
    line-height: 1;
    overflow-x: hidden;
    font-size: 100%;
    min-height: 100%;
    font-family: 'Sora', sans-serif;
  }

  img {
    display: block;
  	width: 100%;
  	height: auto;
  }
`;
export default GlobalStyles;